import React from "react"

export const NavbarIconsElectronics = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path
                    d="M19.7044 2.0625L6 19H14L12.0306 29.8269C12.0269 29.8481 12.0278 29.8699 12.0334 29.8908C12.039 29.9116 12.0491 29.9309 12.063 29.9475C12.0769 29.964 12.0942 29.9772 12.1138 29.9863C12.1334 29.9954 12.1547 30.0001 12.1763 30V30C12.1992 30 12.2218 29.9946 12.2423 29.9843C12.2628 29.974 12.2807 29.959 12.2944 29.9406L26 13H18L19.9788 2.17188C19.9814 2.1503 19.9795 2.12841 19.973 2.10766C19.9665 2.0869 19.9557 2.06776 19.9413 2.0515C19.9269 2.03524 19.9092 2.02224 19.8893 2.01336C19.8695 2.00448 19.848 1.99993 19.8263 2V2C19.8025 2.0001 19.779 2.0058 19.7578 2.01666C19.7367 2.02753 19.7183 2.04323 19.7044 2.0625V2.0625Z"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
        </svg>
    )
}

export const NavbarIconsTools = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.9288 11.1431C13.1595 10.3738 11.9122 10.3737 11.143 11.1429C10.3738 11.9121 10.3739 13.1593 11.1432 13.9286L25.0715 27.8569C25.8408 28.6262 27.088 28.6263 27.8573 27.8571C28.6265 27.0879 28.6264 25.8406 27.857 25.0713L13.9288 11.1431Z"
                stroke="white"
                stroke-miterlimit="10"
            />
            <path
                d="M11.1487 11.1487C10.965 11.3323 10.8193 11.5503 10.7199 11.7903C10.6205 12.0302 10.5693 12.2874 10.5693 12.5471C10.5693 12.8068 10.6205 13.064 10.7199 13.3039C10.8193 13.5439 10.965 13.7619 11.1487 13.9455L13.953 16.7499L16.7499 13.953L13.9455 11.1487C13.7619 10.965 13.5439 10.8193 13.3039 10.7199C13.064 10.6205 12.8068 10.5693 12.5471 10.5693C12.2874 10.5693 12.0302 10.6205 11.7903 10.7199C11.5503 10.8193 11.3323 10.965 11.1487 11.1487Z"
                fill="white"
            />
            <path
                d="M3 12H6"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M5.63623 5.63599L7.75748 7.75724"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M12 3V6"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M18.3637 5.63599L16.2424 7.75724"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M7.75748 16.2427L5.63623 18.3639"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
        </svg>
    )
}

export const NavbarIconsCloses = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.66 3C19.66 3 18.2362 3.5 16 3.5C13.7638 3.5 12.34 3 12.34 3C12.115 3.00001 11.8917 3.03804 11.6794 3.1125L2 6.5L3.03938 12L6.09438 12.345C6.4685 12.3873 6.81302 12.5688 7.05952 12.8534C7.30603 13.138 7.43649 13.5049 7.425 13.8813L7 29H25L24.575 13.8813C24.5635 13.5049 24.694 13.138 24.9405 12.8534C25.187 12.5688 25.5315 12.3873 25.9056 12.345L28.9606 12L30 6.5L20.3206 3.1125C20.1083 3.03804 19.885 3.00001 19.66 3V3Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.832 3.29102C20.5486 4.35612 19.9212 5.29774 19.0473 5.96945C18.1735 6.64117 17.1023 7.00534 16.0001 7.00534C14.8979 7.00534 13.8266 6.64117 12.9528 5.96945C12.079 5.29774 11.4516 4.35612 11.1682 3.29102"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const NavbarIconsBags = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_2_8208)">
                <rect
                    x="5.83325"
                    y="11.1666"
                    width="20.3333"
                    height="20.3333"
                    stroke="white"
                />
                <path
                    d="M12 5.33337C12 4.80808 12.1035 4.28793 12.3045 3.80263C12.5055 3.31733 12.8001 2.87637 13.1716 2.50494C13.543 2.1335 13.984 1.83887 14.4693 1.63785C14.9546 1.43683 15.4747 1.33337 16 1.33337C16.5253 1.33337 17.0454 1.43683 17.5307 1.63785C18.016 1.83887 18.457 2.1335 18.8284 2.50494C19.1999 2.87637 19.4945 3.31733 19.6955 3.80263C19.8965 4.28794 20 4.80808 20 5.33337L20 11.3334L16 11.3334L12 11.3334L12 5.33337Z"
                    stroke="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2_8208">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const NavbarIconsDishes = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.59299 2.98621L26.6199 26.0131C27.0568 26.45 27.3022 27.0427 27.3022 27.6606C27.3022 28.2785 27.0568 28.8711 26.6199 29.3081V29.3081C26.1828 29.7449 25.5902 29.9902 24.9724 29.9902C24.3545 29.9902 23.7619 29.7449 23.3249 29.3081L17.6999 23.5862C17.3317 23.2124 17.1252 22.709 17.1249 22.1843V21.8387C17.1249 21.5738 17.0723 21.3115 16.9702 21.0671C16.8681 20.8227 16.7184 20.601 16.5299 20.415L15.8036 19.7443C15.5571 19.5168 15.2573 19.355 14.9318 19.2738C14.6063 19.1925 14.2656 19.1944 13.9411 19.2793V19.2793C13.4293 19.4129 12.8915 19.4103 12.3811 19.2718C11.8706 19.1334 11.4052 18.8638 11.0311 18.49L5.69174 13.15C2.52424 9.98246 1.35861 5.19933 3.59299 2.98621Z"
                stroke="white"
                stroke-linejoin="round"
            />
            <path
                d="M24.9998 2.00171L20.1716 6.82983C19.8001 7.20128 19.5054 7.64228 19.3043 8.12764C19.1032 8.613 18.9998 9.13322 18.9998 9.65858V10.5873C18.9998 10.7187 18.9739 10.8489 18.9236 10.9703C18.8733 11.0917 18.7996 11.2019 18.7066 11.2948L17.9998 12.0017"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20 14.0007L20.7069 13.2939C20.7998 13.2009 20.9101 13.1272 21.0315 13.0769C21.1529 13.0266 21.283 13.0007 21.4144 13.0007H22.3431C22.8685 13.0007 23.3887 12.8972 23.8741 12.6962C24.3594 12.4951 24.8004 12.2004 25.1719 11.8289L30 7.00073"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M27.4998 4.50244L22.4998 9.50244"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.5 23.0011L6.26745 29.2686C5.79863 29.7373 5.16286 30.0006 4.49995 30.0006C3.83704 30.0006 3.20127 29.7373 2.73245 29.2686V29.2686C2.26378 28.7998 2.00049 28.164 2.00049 27.5011C2.00049 26.8382 2.26378 26.2024 2.73245 25.7336L7.99995 20.5011"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const NavbarIconsHome = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 13.2494V27.9994C5 28.2646 5.10536 28.519 5.29289 28.7065C5.48043 28.894 5.73478 28.9994 6 28.9994H12V20.4994C12 20.1016 12.158 19.72 12.4393 19.4387C12.7206 19.1574 13.1022 18.9994 13.5 18.9994H18.5C18.8978 18.9994 19.2794 19.1574 19.5607 19.4387C19.842 19.72 20 20.1016 20 20.4994V28.9994H26C26.2652 28.9994 26.5196 28.894 26.7071 28.7065C26.8946 28.519 27 28.2646 27 27.9994V13.2494"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M30 16.0011L16.6806 3.25111C16.3681 2.92111 15.6375 2.91736 15.3194 3.25111L2 16.0011"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M25 11.1873V3.99976H22V8.31226"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const NavbarIconsOffice = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 14.5C14 13.9696 14.2107 13.4609 14.5858 13.0858C14.9609 12.7107 15.4696 12.5 16 12.5C16.5304 12.5 17.0391 12.7107 17.4142 13.0858C17.7893 13.4609 18 13.9696 18 14.5"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M28 12.5H29"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4 12.5H3"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4 12.5C4 18.5 5 20.5 9 20.5C13 20.5 14 18.5 14 12.5C14 12.5 13 11.5 9 11.5C5 11.5 4 12.5 4 12.5Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M28 12.5C28 18.5 27 20.5 23 20.5C19 20.5 18 18.5 18 12.5C18 12.5 19 11.5 23 11.5C27 11.5 28 12.5 28 12.5Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const NavbarIconsCar = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.3814 14.4935L17.4127 19.2123C17.2912 19.3806 17.1435 19.5283 16.9752 19.6498C16.5688 19.9333 16.0671 20.0451 15.5788 19.9609C15.0905 19.8767 14.6551 19.6034 14.3671 19.2002C14.0791 18.797 13.9618 18.2965 14.0405 17.8073C14.1193 17.3181 14.3877 16.8797 14.7877 16.5873L19.5064 13.6185C19.6137 13.5437 19.7413 13.5037 19.8721 13.5037C20.0028 13.5037 20.1304 13.5437 20.2377 13.6185C20.3724 13.7158 20.4631 13.8624 20.4901 14.0264C20.517 14.1903 20.478 14.3583 20.3814 14.4935Z"
                fill="white"
            />
            <path
                d="M16 4C8.26876 4 2.00001 10.2625 2.00001 17.9937C1.99527 21.4143 3.24754 24.7173 5.51876 27.275C5.58751 27.35 5.65001 27.425 5.71876 27.4937C5.86666 27.6539 6.0462 27.7815 6.24601 27.8687C6.44582 27.9558 6.66154 28.0005 6.87951 27.9999C7.09748 27.9993 7.31296 27.9534 7.5123 27.8652C7.71163 27.777 7.89048 27.6484 8.03751 27.4875C9.05095 26.3862 10.2818 25.507 11.6524 24.9057C13.0229 24.3043 14.5033 23.9938 16 23.9938C17.4967 23.9938 18.9771 24.3043 20.3476 24.9057C21.7182 25.507 22.9491 26.3862 23.9625 27.4875C24.1095 27.6484 24.2884 27.777 24.4877 27.8652C24.6871 27.9534 24.9025 27.9993 25.1205 27.9999C25.3385 28.0005 25.5542 27.9558 25.754 27.8687C25.9538 27.7815 26.1334 27.6539 26.2813 27.4937L26.4813 27.275C28.7525 24.7173 30.0048 21.4143 30 17.9937C30 10.2625 23.7313 4 16 4Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16 8V10"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M26 18H24"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M8 18H6"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M10.3431 12.3439L8.92871 10.9296"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M21.657 12.3439L23.0714 10.9296"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
        </svg>
    )
}

export const NavbarIconsSets = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28 21.3357V10.6632C27.9996 10.3139 27.9077 9.97076 27.7334 9.66801C27.5592 9.36526 27.3087 9.11342 27.0069 8.93756L17.5069 3.40881C17.0492 3.14234 16.529 3.00195 15.9994 3.00195C15.4697 3.00195 14.9496 3.14234 14.4919 3.40881L4.99312 8.93756C4.69131 9.11342 4.44081 9.36526 4.26657 9.66801C4.09233 9.97076 4.00043 10.3139 4 10.6632V21.3357C4.00021 21.6852 4.09201 22.0286 4.26626 22.3316C4.44051 22.6346 4.69112 22.8866 4.99312 23.0626L14.4931 28.5913C14.951 28.8574 15.4711 28.9976 16.0006 28.9976C16.5302 28.9976 17.0503 28.8574 17.5081 28.5913L27.0081 23.0626C27.3099 22.8864 27.5603 22.6343 27.7343 22.3314C27.9083 22.0284 27.9999 21.6851 28 21.3357Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.3125 9.62402L16 16.499L27.6875 9.62402"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16 28.999V16.499"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const NavbarIconsGifts = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 6.5V9.99999H19.5C20.1922 9.99999 20.8689 9.79472 21.4445 9.41014C22.0201 9.02555 22.4687 8.47893 22.7336 7.83939C22.9985 7.19985 23.0678 6.49611 22.9327 5.81718C22.7977 5.13825 22.4643 4.51461 21.9749 4.02513C21.4854 3.53564 20.8617 3.2023 20.1828 3.06725C19.5039 2.9322 18.8001 3.00152 18.1606 3.26642C17.5211 3.53133 16.9744 3.97993 16.5899 4.5555C16.2053 5.13107 16 5.80776 16 6.5V6.5Z"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M16 6.5C16 7.46625 16 9.99999 16 9.99999H12.5C11.8078 9.99999 11.1311 9.79472 10.5555 9.41014C9.97993 9.02555 9.53133 8.47893 9.26642 7.83939C9.00152 7.19985 8.9322 6.49611 9.06725 5.81718C9.2023 5.13825 9.53564 4.51461 10.0251 4.02513C10.5146 3.53564 11.1383 3.2023 11.8172 3.06725C12.4961 2.9322 13.1999 3.00152 13.8394 3.26642C14.4789 3.53133 15.0256 3.97993 15.4101 4.5555C15.7947 5.13107 16 5.80776 16 6.5V6.5Z"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M26 10H6C4.89543 10 4 10.8954 4 12V15C4 16.1046 4.89543 17 6 17H26C27.1046 17 28 16.1046 28 15V12C28 10.8954 27.1046 10 26 10Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M26 17V26C26 26.7956 25.6839 27.5587 25.1213 28.1213C24.5587 28.6839 23.7956 29 23 29H9C8.20435 29 7.44129 28.6839 6.87868 28.1213C6.31607 27.5587 6 26.7956 6 26V17"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16 10V29"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
