export const CATALOG_PRODUCT_TITLE = [
    {
        label: 'catpr1.png',
        h5: 'Електроніка',
        p1: 'Повер банки',
        p2: 'Бездротові зарядки',
        p3: 'Ліхтарі та лампи',
        p4: 'Музичні колонки',
        p5: 'Кабелі зарядки',
    },
    {
        label: 'catpr2.png',
        h5: 'Одяг',
        p1: 'Футболки',
        p2: 'Реглани',
        p3: 'Жилети',
        p4: 'Худі',
        p5: 'Кепки',
    },
    {
        label: 'catpr3.png',
        h5: 'Сумки',
        p1: 'Дорожні сумки',
        p2: 'Рюкзаки',
        p3: 'Сумки для ноутбука',
        p4: 'Сумки на пояс',
        p5: 'Шопери',
    }
    ,
    {
        label: 'catpr4.png',
        h5: 'Інструменти',
        p1: 'Мультитули',
        p2: 'Рулетки',
        p3: 'Ножі',
        p4: 'Набори інструментів',
        p5: 'Спецілізовані інструменти',
    }
    ,
    {
        label: 'catpr5.png',
        h5: 'Посуд',
        p1: 'Пляшки для води',
        p2: 'Термочашки',
        p3: 'Термоси',
        p4: 'Керамічні чашки',
        p5: 'Заварники',
    }
    ,
    {
        label: 'catpr1.png',
        h5: 'Авто',
        p1: 'Тримачі для телефону',
        p2: 'Кабелі зарядки',
        p3: 'Органайзери',
        p4: 'Подушки та пледи',
        p5: 'Ароматизатори',
    }
    ,
    {
        label: 'catpr2.png',
        h5: 'Дім',
        p1: 'Парасолі',
        p2: 'Рушники',
        p3: 'Пледи',
        p4: 'Дзеркала',
        p5: 'Звооложувачі',
    }
    ,
    {
        label: 'catpr3.png',
        h5: 'Офіс',
        p1: 'Блокноти',
        p2: 'Ручки',
        p3: 'Органайзери',
        p4: 'Аксессуари',
        p5: 'Антистреси',
    }
    ,
    {
        label: 'catpr4.png',
        h5: 'Набори',
        p1: 'Новорічний',
        p2: 'Весняний',
        p3: 'Велком пак',
        p4: 'Автомобільний',
        p5: 'Діловий',
    }
    ,
    {
        label: 'catpr5.png',
        h5: 'Пакування',
        p1: 'Коробки',
        p2: 'Тубуси',
        p3: 'Пакети',
        p4: 'Сумки',
        p5: 'Рамки',
    }
]



export const CATALOG_PRODUCT_CLIENT = [
    {
        label: 'catcl1.png',
        h5: 'Рекламні агентства',
        p1: '1. Унікальність пропозиції',
        p2: '2. Надійність підрядника',
        p3: '3. Ціна-якість',
        p4: '4. Оперативність',
    },
    {
        label: 'catcl2.png',
        h5: 'Дилери',
        p1: '1. Унікальність пропозиції',
        p2: '2. Мінімальна залученість',
        p3: '3. Вчасність поставок',
        p4: '4. Наявність продукції в країні',
        p5: '',
    },
    {
        label: 'catcl3.png',
        h5: 'Сувенірні компанії ',
        p1: '1. Унікальність пропозиції',
        p2: '2. Вчасність поставок',
        p3: '3. Ціна-якість ',
        p4: '4. Наявність продукції в країні',
        p5: '',
    },
    {
        label: 'catcl4.png',
        h5: 'Рекламні агентства',
        p1: '1. Різноманіття асортименту для друку',
        p2: '2. Вчасність поставок ',
        p3: '3. Ціна-якість',
        p4: '',
        p5: '',
    }
]