
export const AuthIconTougch = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.43682 15.6693L5.40244 16.2553L5.97666 15.1943C7.27119 13.0264 11.2329 7.96777 20.0001 7.96777C28.9845 7.96777 32.8126 13.4615 34.0704 15.2693L34.7079 16.2615L36.7095 14.999L36.0423 13.9553C35.3485 12.9514 33.8923 10.867 31.2938 9.02558C28.1368 6.78184 24.336 5.64355 20.0001 5.64355C9.99463 5.64355 5.41494 11.5115 3.90635 14.0225L3.33057 14.999L4.21885 15.535C4.25924 15.5665 4.30179 15.5953 4.34619 15.6209C4.37553 15.6386 4.40578 15.6548 4.43682 15.6693Z" fill="#1E1E1E"/>
    <path d="M29.6264 13.4622C26.8498 11.202 23.5233 10.0051 19.9998 10.0051C12.3811 10.0051 7.31935 15.1614 5.67716 20.2762C4.17325 24.9997 6.00138 31.8442 6.07794 32.1372L6.42013 33.2161L8.75841 32.7137L8.3881 31.5418C8.37169 31.4832 6.66154 25.0629 7.96544 20.9786C8.66857 18.7848 10.1318 16.6504 11.9772 15.1356C14.2037 13.3114 16.9803 12.3434 19.9998 12.3434C27.0764 12.3434 31.0881 17.8598 32.2225 20.7895C33.1029 23.0497 32.8998 25.6192 31.7318 27.1957C31.2998 27.7731 30.5381 28.4512 29.3787 28.3926C26.7373 28.2582 26.2803 26.9528 25.6451 24.4668C25.3404 23.27 25.0279 22.0309 24.249 21.0293C23.3006 19.7989 21.9209 19.2043 20.0084 19.2043C17.9514 19.2043 16.3936 19.8825 15.3693 21.2137C13.135 24.1262 14.5881 29.0989 14.6498 29.3082L14.667 29.3747C14.7772 29.6762 17.4858 36.9254 24.7397 38.7247L25.8936 38.9754L26.5256 36.7325L25.3326 36.4645C19.5342 35.0247 17.1131 29.0903 16.9272 28.6215C16.7176 27.8609 16.5841 27.0813 16.5287 26.2942C16.4443 25.1645 16.5037 23.6075 17.2654 22.6114C17.824 21.8832 18.7217 21.5317 19.9998 21.5317C22.3272 21.5317 22.717 22.67 23.3264 25.0309C23.9186 27.3325 24.7326 30.4879 29.2514 30.7145C30.9865 30.7981 32.5529 30.0278 33.6529 28.5543C35.3037 26.345 35.617 22.9629 34.449 19.9497C33.5365 17.5778 31.7764 15.22 29.6264 13.4622Z" fill="#1E1E1E"/>
    <path d="M12.0454 26.8131C11.7743 24.6038 12.1556 21.2663 14.1196 19.0959C15.4821 17.5889 17.4626 16.8303 19.9939 16.8303C22.9228 16.8303 25.14 18.1366 26.5962 20.7061C27.3129 21.9955 27.7864 23.4056 27.9931 24.8663L28.1048 26.0139L30.4485 25.842C30.4508 25.4345 30.4247 25.0272 30.3704 24.6233C30.3618 24.5233 30.0915 22.0873 28.7298 19.6428C26.8501 16.2748 23.8368 14.492 20.0001 14.492C16.7579 14.492 14.1759 15.5217 12.3392 17.5475C9.8509 20.2928 9.34309 24.2858 9.68996 27.1061V27.1319C10.3126 31.392 11.9532 34.7991 14.8556 37.8459L15.7618 38.7225L17.3986 37.0202L16.5907 36.2553C14.0345 33.5772 12.5868 30.5803 12.0454 26.8131Z" fill="#1E1E1E"/>
    <path d="M10.3492 5.67699C11.2148 5.15199 14.2766 3.58558 20 3.58558C26.8391 3.58558 29.8781 5.82933 29.9117 5.84574L30.9976 6.53793L32.2656 4.5684L31.2328 3.89965C31.1922 3.86996 31.15 3.84027 31.1078 3.81215C30.1172 3.16761 26.6047 1.25043 20 1.25043C13.1477 1.25043 9.62891 3.31918 8.79609 3.88402L7.73438 4.5723L9 6.54183L10.3492 5.67699Z" fill="#1E1E1E"/>
    <path d="M30.5149 32.4379C27.9079 32.7309 25.7923 32.2294 24.2095 30.9653C21.5345 28.8223 21.1876 25.0973 21.179 25.0278L21.0829 23.8653L18.6978 24.0395L18.8001 25.2114C18.8173 25.4036 19.2063 29.9575 22.6767 32.7536C24.404 34.1512 26.5626 34.8544 29.1017 34.8544C29.6646 34.8532 30.227 34.8196 30.786 34.7536L32.0821 34.5903L31.6056 32.2692L30.5149 32.4379Z" fill="#1E1E1E"/>
    </svg>
    
  )
}
